.file-upload-section {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  margin: 15px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.upload-header {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.upload-header-icon {
  margin-right: 8px;
  color: #007bff;
}

.file-upload-container {
  display: flex;
  flex-direction: column;
}

.input-container {
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #007bff;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 12px;
  background-color: #f0f7ff;
  position: relative;
  overflow: hidden;
}

.input-container:hover {
  background-color: #e1ecff;
}

.input-container p {
  margin: 0;
  color: #007bff;
  font-size: 14px;
  font-weight: 500;
  z-index: 1;
}

.file-icon {
  position: absolute;
  font-size: 48px !important;
  color: rgba(0, 123, 255, 0.1);
  z-index: 0;
}

.action-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.agreement-container {
  font-size: 13px;
  color: #555;
  flex: 1;
  margin-right: 10px;
}

.process-resume-button {
  background-color: #007bff !important;
  color: white !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
  text-transform: none !important;
  transition: all 0.3s ease !important;
  box-shadow: 0 2px 4px rgba(0, 123, 255, 0.2) !important;
}

.process-resume-button:hover:not(:disabled) {
  background-color: #0056b3 !important;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.3) !important;
}

.process-resume-button:disabled {
  background-color: #e0e0e0 !important;
  color: #9e9e9e !important;
  box-shadow: none !important;
}

.input-container.disabled {
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  .file-upload-section {
    width: calc(100% - 30px); /* Subtract left and right padding to match other elements */
    margin: 10px auto; /* Center the element and add vertical spacing */
    padding: 10px 15px;
  }

  .upload-header {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .upload-header-icon {
    font-size: 18px;
  }

  .input-container {
    min-height: 40px;
    padding: 8px;
  }

  .input-container p {
    font-size: 14px;
  }

  .agreement-container {
    font-size: 14px;
  }

  .process-resume-button {
    font-size: 14px !important;
    padding: 8px 16px !important;
    height: 36px;
  }

  .MuiCheckbox-root {
    padding: 4px;
  }

  .action-container {
    margin-top: 8px;
  }
}
