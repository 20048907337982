.app-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 100vh;
  background-color: #232438;
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
}

.hero-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url('../../../public/hero.png');
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  z-index: 0;
}

.hero-background::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, 
              rgba(35, 36, 56, 0) 0%,
              rgba(35, 36, 56, 0.3) 30%,
              rgba(35, 36, 56, 0.7) 60%,
              rgba(35, 36, 56, 1) 100%);
  z-index: 1;
}

.content-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  flex-grow: 1;
  position: relative;
  z-index: 2;
  padding-top: 20px;
  padding-bottom: 20px;
}

.upload-container {
  margin-top: 40px; /* Add space between filters and file upload */
}

.sidebar-container,
.main-filter-container,
.joblist-container {
  border: 1px solid #E0E0E0;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.sidebar-container {
  position: sticky;
  top: 20px;
  align-self: flex-start; /* Align to the top of the flex container */
  padding: 20px;
  z-index: 2;
  width: 26%;
  border: 1px solid #5d737e;
  background-color: rgba(255, 255, 255);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin-left: 20px;
}

.main-content {
  display: flex;
  flex-direction: column;
  width: 74%;
  margin-right: 20px;
  position: relative;
  z-index: 1;
}

.main-filter-container,
.joblist-container {
  border: 1px solid #5d737e;
  background-color: rgba(255, 255, 255);
  border-radius: 20px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
}

/* Ensure the last container doesn't have a bottom margin */
.joblist-container:last-child {
  margin-bottom: 0;
}

.joblist-container {
  width: 100%;
  height: auto;
  border: 1px solid #5d737e;
  background-color: rgba(255, 255, 255); /* Change this */
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  position: relative; /* Add this */
  z-index: 1; /* Add this */
}


/* Toggle Filters Button Style */
.toggle-filters-btn {
  display: block;
  width: auto; /* Adjust width as needed */
  padding: 10px 20px;
  margin: 10px 0;
  background-color: #4CAF50; /* Change color as per your theme */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.toggle-filters-btn:hover {
  background-color: #45a049; /* Darker shade for hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Mobile specific styles */
@media screen and (max-width: 768px) {
  .content-container {
    flex-direction: column;
    gap: 0px;
  }

  .sidebar-container {
    width: 100%;
    margin: 0;
    background: transparent;
    border: none;
    position: relative;
    box-shadow: none;
    border-radius: 0;
    padding: 20px;
  }

  .upload-container {
    margin-top: 25px; /* Slightly less space on mobile */
  }

.joblist-container {
  background: transparent;
  border: none;
}

  .main-content {
    width: 100%;
    margin-right: 0px;
  }

  .main-filter-container {
    width: 100%;
    box-shadow: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    background-color: transparent;
    padding: 0;/* Overlap the border of sidebar container to remove the gap */
    border-radius: 0;
  }

  .hero-section {
    height: 150px;
    padding-top: 0;
  }

  .search-bar {
    margin: 0;
    padding: 20px;
    border: 1px solid #5d737e; /* Mid Blue */
    border-radius: 10px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    width: 100%;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-size: 1rem;
    line-height: 2.5;
    height: 55px;
    background-color: white;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .more-filters-btn {
    display: block;
    width: 100%;
    padding: 10px 20px;
    margin: 10px 0;
    background-color: #61dafb; /* Example color */
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
  }

  .more-filters-btn:hover {
    background-color: #50c0eb; /* Slightly darker on hover */
  }

  .search-container {
    order: -1;
    padding: 0;
    margin-bottom: 20px;
  }

  .sidebar-container > *,
  .main-filter-container > * {
    padding-top: 0;
    margin-top: 0;
  }

}
