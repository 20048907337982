.app-footer {
  background-color: #fff;
  color: #6c757d;
  padding: 20px 0;
  text-align: center;
  z-index: 0;
  margin-top: auto;
}

.footer-content {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
}

.footer-links {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 0;
}

.footer-links li a {
  color: #6c757d;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links li a:hover {
  color: #343a40;
}

@media (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}
