.user-settings-container {
    background: #fff;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 12px;
}

.custom-button {
  margin-top: 30px;
  padding: 12px 24px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.custom-button:hover {
  background-color: #0056b3;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
