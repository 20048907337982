/* SimpleHeader.css */
.simple-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.simple-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  height: 80px;
  transition: background-color 0.3s ease;
  position: relative;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.logo-container img {
  height: 45px;
  width: auto;
  object-fit: contain;
  display: block;
  transition: transform 0.2s ease;
}

.logo-container img:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 968px) {
  .simple-header-container {
    padding: 0 1rem;
  }
}
