.filter-heading {
  margin-bottom: 20px;
}

.sidebar-filter-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: transparent;
}

.sidebar-filter-item {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  width: 100%;
  background-color: transparent;
}

.sidebar-filter-item input[type="text"].filter-sidebar-input {
  margin: 0;
  padding: 20px;
  border: 1px solid #3f7cac; /* Mid Blue */
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  width: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-size: 1rem;
  line-height: 2.5;
  color: inherit;
  height: 55px;
  background-color: white;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sidebar-filter-item .select__control,
.sidebar-filter-item .custom-select-input {
  padding: 10px;
  border: 1px solid #3f7cac; /* Mid Blue */
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.sidebar-filter-item .select__control:hover,
.sidebar-filter-item .custom-select-input:hover {
  border-color: #5d737e; /* Greyish Blue for hover */
}

.sidebar-filter-item .select__control:focus,
.sidebar-filter-item .custom-select-input:focus {
  border-color: #00243c; /* Dark Blue for focus */
  box-shadow: 0 0 0 0.2rem rgba(0, 36, 60, 0.25);
  outline: none;
}

.toggle-button-group {
  margin-top: 30px;
  display: flex;
  width: 100%;
  border: 1px solid #3f7cac;
}

.toggle-button-group [aria-label="Show local jobs"],
.toggle-button-group [aria-label="Show international jobs"] {
  color: #000;
  background-color: #fff; /* White */
  border-radius: 10px;
  text-transform: none;
  flex: 1;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.toggle-button-group [aria-label="Show international jobs"] {
  border: 1px solid #498ed8;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 10px 10px 0;
}

.toggle-button-group [aria-label="Show local jobs"] {
  border: 1px solid #498ed8;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px 0 0 10px;
}

.toggle-button-group [aria-label="Show local jobs"][aria-pressed="true"],
.toggle-button-group [aria-label="Show international jobs"][aria-pressed="true"] {
  background-color: #498ed8; 
}

.custom-select-container {
  width: 100%;
  border: 1px solid #3f7cac;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  flex: 1;
  margin-bottom: 30px;
  align-items: center;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
}

/* Updated styles */
.filter-sidebar-input {
  margin: 0;
  padding: 10px;
  border: 1px solid #3f7cac; /* Mid Blue */
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  width: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-size: 1rem;
  line-height: 2.5;
  color: inherit;
  height: 40px;
  background-color: white;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Focus styles */
.filter-sidebar-input:focus {
  border-color: #00243c; /* Dark Blue for focus */
  box-shadow: 0 0 0 0.2rem rgba(0, 36, 60, 0.25);
  outline: none;
}

/* Remove margin-bottom for the last filter item */
.sidebar-filter-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0px;
}

.select__menu-list::-webkit-scrollbar {
  width: 12px;
}

.select__menu-list::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
}

.select__menu-list::-webkit-scrollbar-thumb {
  background-color: #e8e8e8; /* Subtle Grey */
  border-radius: 10px;
}

.select__menu-list::-webkit-scrollbar-thumb:hover {
  background: #5d737e; /* Greyish Blue for hover */
}


@media screen and (max-width: 1350px) {


  .sidebar-container h3 {
    display: none;
  }

  .sidebar-filter-item {
    border: none;
    box-shadow: none;
    margin-bottom: 0px; /* Adds space between filters */
  }

  .sidebar-filter-item:nth-last-child(2) {
    padding-bottom: 0px;
  }

  .sidebar-filter-item:last-child {
    display: none;
  }


}
