.JobListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px; /* Further reduced margin bottom */
  margin-top: 0px; /* Further reduced margin top */
  padding: 0px; /* Added padding to the header */
  background-color: #ffffff; /* Added background color to the header */
}

.JobCountDisplay {
  font-size: 16px;
  color: #333;
  margin-right: 16px; /* Added margin right to create space between job count and sort */
}

.JobListContainer {
  margin-top: 0px; /* Further reduced margin top */
  margin-bottom: 0px; /* Further reduced margin bottom */
}

.PaginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.PaginationContainer button {
  border: none;
  background-color: #f1f1f1;
  color: #333;
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.PaginationContainer button:hover {
  background-color: #ddd;
}

.PaginationContainer button.active {
  background-color: #333;
  color: #fff;
}

.LoadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .JobCountDisplay {
    margin-right: 8px;
    color: white;
  }

  .JobListHeader {
    background-color: transparent; /* Added background color to the header */
  }
}