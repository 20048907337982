.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  height: 80px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.logo-container img {
  height: 45px;
  width: auto;
  object-fit: contain;
  display: block;
}

.nav-links.left,
.nav-links.right {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  gap: 20px;
}

.nav-links.left {
  margin-right: auto;
}

.nav-links.right {
  margin-left: auto;
}

.nav-button,
.signup-button,
.login-button {
  background: none;
  border: 2px solid transparent;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  transition: color 0.3s ease, border-color 0.3s ease, background-color 0.3s ease;
}

.auth-button {
  position: relative;
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.mobile-menu-container {
  display: none;
}

/* navbar at user-backend */
.header-container--backend {
  background-color: gray;
}

.header-container--backend .mobile-menu-drawer,
.header-container--backend .mobile-auth-menu {
  background-color: gray;
}

/* Auth Menu - Common Styles */
.auth-menu {
  position: fixed;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  padding: 20px;
  visibility: hidden;
}

/* Desktop Auth Menu */
.desktop-auth-menu {
  top: 60px;
  right: 2rem;
  width: 200px;
  border-radius: 8px;
  transform: translateY(-20px);
  opacity: 0;
  pointer-events: none;
  transition: transform 0.2s ease, opacity 0.2s ease, visibility 0.2s ease;
}

.desktop-auth-menu.open {
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

/* Auth Items - Common Styles */
.auth-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.auth-items li {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.auth-items .profile-info {
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.auth-items button {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 0;
  display: block;
  width: 100%;
  text-align: left;
  transition: color 0.2s ease;
}

.auth-items button:hover {
  color: #007bff;
}

.auth-items .user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.auth-items span {
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

@media screen and (max-width: 968px) {
  body {
    padding-top: 0;
  }

  .header-container {
    padding: 0 1rem;
  }

  .nav-links.left,
  .nav-links.right {
    display: none;
  }

  .mobile-menu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 1rem;
  }

  .mobile-menu-container .logo-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }

  .mobile-menu-drawer,
  .mobile-auth-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 250px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
    z-index: 1001;
    padding: 20px;
  }

  .mobile-menu-drawer {
    left: 0;
    transform: translateX(-100%);
  }

  .mobile-auth-menu {
    right: 0;
    transform: translateX(100%);
  }

  .mobile-menu-drawer.open,
  .mobile-auth-menu.open {
    transform: translateX(0);
    visibility: visible;
  }

  .close-menu-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .mobile-menu-items,
  .mobile-auth-items {
    list-style: none;
    padding: 0;
    margin-top: 50px;
  }

  .mobile-menu-items li,
  .mobile-auth-items li {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .mobile-menu-items a,
  .mobile-auth-items button {
    text-decoration: none;
    color: #333;
    font-size: 18px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px 0;
    display: block;
    width: 100%;
    text-align: left;
  }

  .mobile-auth-menu .auth-items {
    margin-top: 50px;
  }

  .menu-overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
