.JobCard {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.JobCardContent {
  display: flex;
  flex-direction: row;
}

.JobLogoContainer {
  flex: 0 0 120px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-right: 20px;
  margin-top: 20px;
}

.JobLogo {
  max-height: 100px;
  max-width: 100px;
  object-fit: contain;
}

.JobCard__Details {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.JobCard__Title {
  margin-bottom: 20px;
  padding-right: 150px;
  margin-top: 10px;
}

.CountryFlag {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  border-radius: 50%;
  object-fit: cover;
}

.InfoSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

.InfoSection img.CountryFlag {
  align-self: center;
}

.DataField {
  margin-right: 10px;
  font-size: 16px;
  color: #333;
  display: inline-block;
}

.InfoContent {
  display: flex;
  gap: 30px;
}

.JobCard__Title,
.RequirementBox,
.LanguageBox,
.LabelBox {
  font-size: 16px;
  color: #333;
}

.SectionLabel {
  margin-bottom: 10px;
  font-size: 18px;
  padding-right: 5px;
  display: inline-block;
}

.Languages, .EducationRequirements {
  margin-bottom: 20px;
}

.AgencySection {
  margin-bottom: 20px;
}

.RequirementsBox, .LanguagesBox, .LabelsBox {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}

.LanguageBox, .LabelBox, .RequirementBox {
  padding: 4px;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #333;
}

.AdditionalInfo {
  margin-top: 20px;
  font-size: 14px;
  color: #999;
}

.ScoreBadge {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 20px;
  color: black;
}

@media screen and (max-width: 768px) {
  .JobCardContent {
    flex-direction: column;
  }
  .JobCard {
    padding: 0px;
  }

  .JobLogoContainer {
    margin-bottom: 0px;
    margin-top: 40px;
  }

  .JobCard__Title {
    margin-bottom: 20px;
   padding-right: 0px; 
    margin-top: 10px;
    width: 100%; /* Add this to ensure it takes up all available space */
  }

  .CountryFlag {
    margin-right: 10px;
  }

  .InfoSection {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the left */
    gap: 5px;
    margin-bottom: 5px;
  }

  /* Additional adjustments if needed */
  .InfoSection > div {
    align-items: flex-start; /* Align items in each div to the left */
    justify-content: flex-start; /* Align content of each div to the start horizontally */
    width: 100%; /* Optional: Ensure each div takes full width */
  }

  .DataField {
    display: inline-block;
    width: auto;
  }

  .EducationRequirements, 
  .Languages {
    flex-direction: column;
    align-items: flex-start;
  }

  .EducationRequirements .SectionLabel, 
  .Languages .SectionLabel {
    min-width: 140px;
    display: inline-block;
  }

  .RequirementsBox, 
  .LanguagesBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  /* .RequirementBox,
  .LanguageBox {
    width: 100%;
    margin-top: 5px;
  } */

  /* .JobLabels {
    display: none;
  } */

  .ScoreBadge {
    position: absolute;
    z-index: 10; /* Ensure it's above other elements if needed */
  }

  .AdditionalInfo {
    margin-top: 20px;
  }
}
